<template>
	<div>
		<div
			class="pt-2 pb-1 px-6 header d-flex flex-row justify-space-between"
		>
			<h1 class="crud-title">Manage Sections</h1>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="var(--blue)" dark v-bind="attrs" v-on="on">
						Add Section
					</v-btn>
				</template>
				<v-list v-if="addSections.length > 0">
					<v-list-item
						v-for="(section, index) in addSections"
						:key="index"
					>
						<v-list-item-title
							class="section section-menu"
							@click="addSection(section)"
							>{{ section }}</v-list-item-title
						>
					</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item>
						<v-list-item-title>No Data </v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<div class="pa-6 home">
			<draggable
				v-model="addedSections"
				class="drag-area"
				ghost-class="ghost"
				:animation="100"
			>
				<transition-group name="sortable">
					<v-card
						v-for="(section, index) in addedSections"
						:key="section"
						class="
							mb-3
							px-4
							py-2
							section-card
							d-flex
							flex-row
							justify-space-between
						"
					>
						<h1 class="section">
							{{ section }}
						</h1>
						<div class="d-flex flex-row">
							<v-tooltip bottom v-if="index > 0">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-if="index > 0"
										class="mx-2"
										fab
										dark
										v-bind="attrs"
										v-on="on"
										x-small
										color="var(--blue)"
										@click="moveUp(index)"
									>
										<v-icon dark> mdi-arrow-up </v-icon>
									</v-btn>
								</template>
								<span class="tooltip-text">Move Up</span>
							</v-tooltip>
							<v-tooltip
								bottom
								v-if="index < addedSections.length - 1"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										fab
										dark
										v-bind="attrs"
										v-on="on"
										x-small
										color="var(--blue)"
										@click="moveDown(index)"
									>
										<v-icon dark> mdi-arrow-down </v-icon>
									</v-btn>
								</template>
								<span class="tooltip-text">Move Down</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										fab
										dark
										v-bind="attrs"
										v-on="on"
										x-small
										color="#f44336"
										@click="removeSection(section)"
									>
										<v-icon dark>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span class="tooltip-text">Remove</span>
							</v-tooltip>
						</div>
					</v-card>
				</transition-group>
			</draggable>

			<v-btn
				@click="saveSections()"
				depressed
				class="save"
				:loading="loading"
			>
				Save
			</v-btn>
		</div>
	</div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
	components: {
		draggable,
	},
	data() {
		return {
			addedSections: [],
			loading: false,
			sections: [
				'about',
				'faqs',
				'blogs',
				'services',
				'testimonies',
				'qeuestionAndAnswers',
			],
			addSections: [],
		}
	},
	created() {
		this.getSections()
	},
	methods: {
		getSections() {
			const projectId = this.$store.state.app.projectId
			this.axios.get(`/${projectId}/pages/home`).then((res) => {
				if (res.status === 404) {
					console.log(404)
				}
				const data = res.data.meta
				this.addedSections = data.segmentOrder
				this.setAddSectionData()
			})
		},
		moveUp(index) {
			let arr = this.addedSections
			if (index > 0) {
				arr.splice(index - 1, 0, arr.splice(index, 1)[0])
			}
		},
		moveDown(index) {
			let arr = this.addedSections
			if (index < arr.length) {
				arr.splice(index + 1, 0, arr.splice(index, 1)[0])
			}
		},
		saveSections() {
			this.loading = true
			const updatedSections = {
				segmentOrder: this.addedSections,
			}
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/home`,

				data: updatedSections,
			})
				.then((res) => {
					this.loading = false
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						const data = res.data.data.meta
						this.loading = false
						this.addedSections = data.segmentOrder
						this.setAddSectionData()
						console.log(res.data.message)
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.loading = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		removeSection(removeSection) {
			const sections = this.addedSections
			sections.forEach((section, index) => {
				if (section === removeSection) {
					sections.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.addedSections = sections
		},
		setAddSectionData() {
			let addSectionsArray = []
			this.sections.forEach((section) => {
				if (!this.addedSections.includes(section)) {
					addSectionsArray.push(section)
				}
			})
			this.addSections = addSectionsArray
		},
		addSection(section) {
			this.addedSections.push(section)
			this.addSections.splice(section, 1)
		},
	},
}
</script>
<style scoped>
.home {
	max-width: 900px !important;
}
.header {
	border-bottom: 1px solid #eee;
}
.section {
	font-size: 1.2rem;
	color: var(--blue);
	font-weight: 400;
}
.section::first-letter {
	text-transform: uppercase;
}
.sortable-move {
	transition: transform 1s;
}
.section-card {
	cursor: all-scroll;
}
.section-menu {
	cursor: pointer;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.save {
	background-color: var(--blue) !important;
	color: white;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
</style>
